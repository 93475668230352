<template>
  <div class="billingSummary">
    <ul v-if="campaignsStats">
      <li>
        Used for Purchases
        <span v-html="formatPrice(campaignsStats.usedBudget)"></span>
      </li>
      <li>
        Available for Purchases
        <span v-html="formatPrice(campaignsStats.totalBudget)"></span>
      </li>
    </ul>
  </div>
</template>

<script>
import { CAMPAIGNS_STATS } from "@/graphql/brand/query";
export default {
  apollo: {
    campaignsStats: {
      query: CAMPAIGNS_STATS,
    },
  },
};
</script>

<style lang="scss" scoped>
.billingSummary {
  margin-bottom: rem(16px);
  ul {
    background: #fff;
    padding: rem(16px) rem(70px) rem(26px) rem(85px);
    list-style: none;
    width: 620px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media screen and (max-width: 991px) {
      padding: rem(16px) rem(50px) rem(26px);
    }
    @media screen and (max-width: 767px) {
      width: 100%;
      padding: rem(25px) rem(20px);
      flex-wrap: wrap;
    }
    li {
      font-size: rem(18px);
      color: var(--textPrimary);
      font-weight: 700;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      @media screen and (max-width: 767px) {
        width: 48%;
        text-align: center;
      }
      span {
        color: var(--primary);
        display: block;
        margin-top: rem(17px);
        font-size: rem(40px);
        ::v-deep {
          i {
            font-style: normal;
            font-size: rem(26px);
          }
        }
      }
    }
  }
}
</style>
