<template>
  <div class="row">
    <div class="col-6 col-xl-3" v-for="account in accounts" :key="account.id">
      <ul class="socialMedia">
        <li class="socialMedia__icon">
          <img
            :src="`${$config.IMG_HOST}/38x38/${account.logo}`"
            alt=""
            class="img-fluid"
          />
        </li>
        <li>
          {{ account.name }}
          <p>{{ account.followers }}</p>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    accounts: [Object, Array],
  },
};
</script>

<style lang="scss" scoped>
.socialMedia {
  list-style: none;
  display: flex;
  align-items: center;
  padding: rem(14px) rem(12px);
  background: #fff;
  border-radius: 12px;
  @media screen and (max-width: 1199px) {
    margin-bottom: rem(16px) !important;
  }
  &__icon {
    height: 39px;
    width: 39px;
    min-width: 39px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 10px;
    img {
      height: 100%;
      width: 100%;
    }
  }
  li {
    font-size: 11px;
    color: #8998ac;
    p {
      margin: 0;
      font-size: rem(18px);
      font-weight: 500;
      color: #3b485a;
    }
  }
}
</style>
